import React from "react"
import { Layout } from "../../components/Layout"
import { WatchWebinarRecording } from "../../commonComponents"
import { WebinarMiddleSection } from "../../WebinarMiddleSection"
import "./styles.css"
import { Helmet } from "react-helmet"

export default function Webinar() {
  const styles = {
    fontContact: {
      color: "#fff",
      fontFamily: "DM Sans",
    },
    alignContent: {
      display: "flex",
      alignItems: "center",
    },
  }
  return (
    <Layout
      title="Webinar: Improve Workflows Between Product, Engineering And Support"
      keywords="webinar, proactive AI customer support, customer support automation, Improve CSAT, Improve MTTR"
      description="Webinar on how you can seamlessly integrate your CRM, Support platforms and DevOps tools. Reduce ticket resolution time, improve agent performance by leveraging technology."
    >
      <Helmet>
        <link rel="canonical" href="https://irisagent.com/webinar/" />
      </Helmet>
      <section
        style={{
          paddingTop: "40px",
        }}
      >
        <section
          id=" webinar-bg"
          className=" webinar-bg"
          style={{
            backgroundImage: 'url("/img/webinar-bg.png")',
            minHeight: "620px",
          }}
        >
          <div className=" webinar-container" style={styles.fontContact}>
            <img
              className="webinarButton"
              src="/img/WebinarButton.svg"
              alt=""
            />

            <h1 className="webinar-title" style={styles.fontContact}>
              How to Improve Workflows <br />
              Between Product,
              <br />
              Engineering, and Support
            </h1>

            <div className="subtitle-web" style={{ display: "flex" }}>
              <img
                className="calendar"
                src="/img/calendar.svg"
                alt=""
                width={360}
              />
              <p className="time">Aug 3, 2022 11:00AM PT</p>
            </div>
            <WatchWebinarRecording></WatchWebinarRecording>
          </div>
          <div className="webinarHeader">
            <img
              src="/img/WebinarHeader.svg"
              alt=""
              className="webinarHeaderImg"
            />
          </div>
        </section>
      </section>

      <WebinarMiddleSection
        mainImage="/img/WebinarSpeakers.svg"
        // imageTextMiniature="/img/WebinarSpeakers.svg"
        backgroundSectionColor="#FFFFFF"
        descriptionTextTitle={<>Webinar Details</>}
        descriptionTextContent={
          <>
            Many companies today are leaving cross functional data silos
            (support, engineering, success, and product) disconnected and
            underlevered, yet turn around to ask why customer support spending
            is high, escalations are reoccurring, and churn rates are not where
            they should be - costing time, money, and customers.
            <br />
            <br />
            With the right technology and processes, learn how you can
            seamlessly integrate your CRM, support platforms, and DevOps tools
            and reduce ticket resolution times, improve agent performance, and
            uncover siloed information to reduce escalations.
            <br />
            <br />
            <b>Join CX leaders from Zapier, ActiveCampaign, NerdWallet and IrisAgent to learn how to: </b>
            <br />
            <br />
            <ul>
              <li>
                Create automated workflows and intelligent correlations
                cross-functionally
              </li>
              <li>
                Estimate the business impact of escalations and prioritize
                better
              </li>
              <li>
                Understand trends and key customer and product issues from
                support tickets
              </li>
              <li>
                Tools, technology and best practices that enable this
                cross-functional collaboration
              </li>
            </ul>
          </>
        }
      />
      {/* <!-- End Middle Section --> */}
    </Layout>
  )
}
